<messages>["../Email"]</messages>
<template>
  <v-col
    class="d-flex"
    cols="12" md="6">
    <data-card :title="$t ('view.section.lifecycle')">
      <name-value-block :items="lifecycle"/>
    </data-card>
  </v-col>
</template>

<script>
  import {EmptyMark} from '@/app/utils/string'

  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import DataCard from '@/app/core/components/DataCard'

  export default {

    components: {
      NameValueBlock,
      DataCard
    },

    props: {
      data: {
        type: Object,
        required: true
      }
    },

    computed: {
      lifecycle () {
        return [
          ...[
            'creationDate',
            'updateDate',
            'transferDate'
          ].map (d => (
            {
              name: this.$t (`view.label.${d}`),
              value: this.data.viewData[d]
                ? this.formatDate (this.data.viewData[d])
                : EmptyMark
            }
          )),
          {
            name: this.$t ('view.label.expirationDate'),
            value: this.data.emailData.expirationDate
              ? this.formatDate (this.data.emailData.expirationDate)
              : EmptyMark
          }
        ]
      }
    }
  }
</script>
