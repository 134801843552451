<messages>["../Email"]</messages>
<template>
  <v-col
    class="d-flex"
    cols="12">
    <data-card :title="$t ('view.section.contacts')">
      <v-row v-if="contacts.length !== 0 ">
        <v-col
          v-for="contact in contacts"
          :key="`${contact.handle}-${contact.role}`"
          class="justify-space-between pa-4"
          cols="12" sm="6" lg="4">
          <contact-card
            :contact="contact"
            :history="data.viewData.deleted"/>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col v-t="'view.noContacts'"/>
      </v-row>
    </data-card>
  </v-col>
</template>

<script>
  import ContactCard from '@/app/pages/Domain/components/ContactCard'
  import DataCard from '@/app/core/components/DataCard'

  import {createContactDataObject} from '@/app/pages/Contact/constants'

  export default {
    components: {
      ContactCard,
      DataCard
    },

    props: {
      data: {
        type: Object,
        required: true
      }
    },

    computed: {
      contacts () {
        return this.data.contacts.map ((c) => {
          const extra = this.data.contactMap[c.handle]

          return {
            ...c,
            ...(extra
              ? createContactDataObject (extra, c.role, false)
              : {status: 'foreign'})
          }
        })
      }
    }
  }
</script>
