<messages>["./Email"]</messages>
<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-slide-y-transition mode="out-in">
        <v-card v-if="isLoading" key="loading">
          <v-card-text>
            <v-progress-linear indeterminate/>
          </v-card-text>
        </v-card>
        <email-data v-else-if="emailData" key="data" :data="emailData"/>
        <v-alert
          v-else
          key="error"
          type="error">
          {{ $t ('view.invalidEmail', { domain: id }) }}
        </v-alert>
      </v-slide-y-transition>
    </v-col>
  </base-layout>
</template>

<script>
  import {mapActions} from 'vuex'

  import BaseLayout from '@/app/core/components/BaseLayout'

  import EmailData from './View/EmailData'

  export default {
    components: {
      BaseLayout,
      EmailData
    },

    props: {
      id: {
        type: Number,
        required: true
      }
    },

    data () {
      return {
        emailData: null,
        isLoading: false,
        error: false
      }
    },

    watch: {
      id () {
        this.load ()
      }
    },

    created () {
      this.load ()
    },

    methods: {
      ...mapActions ({
        fetchData: 'request/fetchData'
      }),

      load () {
        this.isLoading = true
        this.error = false
        this.emailData = null
        this.fetchData ({
          op: 'emailstatus/load',
          params: {
            id: this.id
          },
          cb: data => {
            this.emailData = data
          },
          cbError: () => {
            this.error = true
          },
          cbFinal: () => {
            this.isLoading = false
          }
        })
      }
    }
  }
</script>
