<messages>["../Email"]</messages>
<template>
  <v-col
    class="d-flex"
    cols="12" md="6">
    <data-card :title="$t ('view.section.basic')">
      <name-value-block :items="basicData"/>
    </data-card>
  </v-col>
</template>

<script>
  import {EmptyMark, toCamelCase} from '@/app/utils/string'

  import RegistryTypeName from '@/app/core/components/RegistryTypeName'
  import ClientLink from '@/app/core/components/ClientLink'
  import SecretText from '@/app/core/components/SecretText'
  import NameValueBlock from '@/app/core/components/NameValueBlock'
  import DataCard from '@/app/core/components/DataCard'

  export default {

    components: {
      NameValueBlock,
      DataCard
    },

    props: {
      data: {
        type: Object,
        required: true
      }
    },

    computed: {
      basicData () {
        return [
          ...['senderAddress', 'destinationAddress', 'handle'].map ((key) => {
            return {
              name: this.$t (`view.label.${key}`),
              value: this.data.emailData[key] || EmptyMark
            }
          }),
          {
            name: this.$t ('view.label.state'),
            value: this.$t (this.data.viewData.deleted
              ? 'view.label.deleted'
              : 'view.label.active')
          },
          {
            name: this.$t ('view.label.registryType'),
            value: true,
            slotValue: this.$createElement (RegistryTypeName, {
              props: {
                value: this.data.emailData.registryId
              }
            })
          },
          ...[
            'maintainer'
          ].map (prop => (
            {
              name: this.$t (`view.label.${prop}`),
              value: this.data.viewData[prop] || EmptyMark
            }
          )),
          ...[
            'creator',
            'updater'
          ].map (type => ({
            name: this.$t (`view.label.${toCamelCase (type, 'client')}`),
            value: !!this.data.viewData[toCamelCase (type, 'memberId')] || EmptyMark,
            ...(
              this.data.viewData[toCamelCase (type, 'memberId')]
                ? {
                  slotValue: this.$createElement (ClientLink, {
                    props: {
                      id: this.data.viewData[toCamelCase (type, 'memberId')]
                    }
                  })
                }
                : {}
            )
          })),
          ...(
            this.data.viewData.authInfo
              ? [{
                name: this.$t ('view.label.authInfo'),
                value: true,
                slotValue: this.$createElement (SecretText, {
                  props: {
                    value: this.data.viewData.authInfo
                  }
                })
              }]
              : []
          )
        ]
      }
    }
  }
</script>
