<template>
  <v-card>
    <v-card-title primary-title>
      <div>
        <div>
          <div class="text-h5">
            <span :class="{deletedItemHeadline: data.viewData.currentVersionDeleted}">
              {{ data.emailData.senderAddress || data.emailData.handle }}
            </span>
          </div>
          <div v-if="data.emailData.handle && data.emailData.senderAddress" class="cgwng-subheading">
            {{ data.emailData.handle }}
          </div>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-row class="justify-end">
        <v-col class="shrink">
          <order-dialog
            :order-id="data.viewData.orderId"
            :object-id="data.emailData.handle || data.emailData.senderAddress"
            type="emailVerification">
            <template #activator="{ on }">
              <v-btn
                v-t="'general.button.showOrder'"
                small
                class="ma-0 elevation-0"
                v-on="on"/>
            </template>
          </order-dialog>
        </v-col>
      </v-row>

      <v-row>
        <!-- email basic data -->
        <email-basic-data :data="data"/>

        <!-- domain dates -->
        <email-date-data :data="data"/>

        <!-- email contacts -->
        <email-contacts :data="data"/>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <back-button/>
    </v-card-actions>
  </v-card>
</template>

<script>
  import OrderDialog from '@/app/core/components/Order/OrderDialog'
  import BackButton from '@/app/core/components/BackButton'

  import EmailContacts from './EmailContacts'
  import EmailBasicData from './EmailBasicData'
  import EmailDateData from './EmailDateData'

  export default {
    name: 'EmailData',

    components: {
      EmailContacts,
      EmailDateData,
      EmailBasicData,
      OrderDialog,
      BackButton
    },

    props: {
      data: {
        type: Object,
        required: true
      }
    }
  }
</script>
